import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './SolutionImg.module.scss';

const SolutionImg = React.memo((props) => {
  return props.image != null ? (
    props.image.childImageSharp != null ? (
      <GatsbyImage
        image={props.image.childImageSharp.gatsbyImageData}
        imgStyle={{
          objectFit: 'contain',
        }}
        className={styles.solutionImage}
      />
    ) : (
      <img alt="" src={props.image} />
    )
  ) : (
    <div />
  );
});

SolutionImg.propTypes = {
  image: PropTypes.object.isRequired,
};

export default SolutionImg;
