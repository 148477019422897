import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import { remark } from 'remark';
import remarkHTML from 'remark-html';
import recommended from 'remark-preset-lint-recommended';

import DetailsImg from '../components/Case_Studies/DetailsImg';
import HeaderImg from '../components/Case_Studies/HeaderImg';
import SolutionImg from '../components/Case_Studies/SolutionImg';
import Content, { HTMLContent } from '../components/Content';
import ContentFooter from '../components/ContentFooter';
import Layout from '../components/Layout';
import Title from '../components/Title';
import * as styles from './CaseStudy.module.scss';

const processMarkDown = (markdown) => {
  return remark()
    .use(recommended)
    .use(remarkHTML)
    .processSync(markdown)
    .toString();
};

export const CaseStudyTemplate = ({
  layout,
  contentComponent,
  topDescription,
  problemSolvedTitle,
  brief,
  solutionTitle,
  solution,
  detailsTitle,
  details,
  image1,
  image2,
  image3,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const PageLayout = layout || Layout;

  return (
    <PageLayout backdropThree>
      <div className={`after-nav ${styles.mainContent}`}>
        {helmet || ''}
        <div className={styles.projectHeader}>
          <section className={`${styles.headerSection}`}>
            <div className={`${styles.articleContent} ${styles.headerContent}`}>
              <Title accentBar text={title} size="1" animated />
              <PostContent content={processMarkDown(topDescription)} />
            </div>
            <HeaderImg image={image1} />
          </section>
        </div>
        <div className={styles.projectBrief}>
          <section
            className={`${styles.articleContent} ${styles.projectBriefContent} ${styles.animatedDate}`}
          >
            <Title accentBar text={problemSolvedTitle} size="2" animated />
            <p>
              <span className={styles.briefBold}>The Brief:</span>{' '}
            </p>
            <PostContent content={processMarkDown(brief)} />
          </section>
        </div>
        <div>
          <section
            className={`${styles.solutionSection} ${styles.animatedDate}`}
          >
            {image2 ? <SolutionImg image={image2} /> : ''}
            <div
              className={`${styles.articleContent} ${styles.solutionContent}`}
            >
              <Title accentBar text={solutionTitle} size="2" animated />
              <PostContent content={processMarkDown(solution)} />
            </div>
          </section>
        </div>
        <div className={styles.projectDetails}>
          <section className={styles.detailsSection}>
            <Title accentBar text={detailsTitle} size="2" animated />
            <div
              className={`${styles.articleContent} ${styles.detailsContent}`}
            >
              <PostContent content={processMarkDown(details)} />
            </div>
            {image3 ? <DetailsImg image={image3} /> : ''}
          </section>
        </div>
        <ContentFooter text="View other case studies" target="/case_studies" />
      </div>
    </PageLayout>
  );
};

CaseStudyTemplate.propTypes = {
  layout: PropTypes.func,
  contentComponent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  topDescription: PropTypes.string.isRequired,
  brief: PropTypes.string.isRequired,
  solution: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  problemSolvedTitle: PropTypes.string.isRequired,
  solutionTitle: PropTypes.string.isRequired,
  detailsTitle: PropTypes.string.isRequired,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object,
  image3: PropTypes.object,
  helmet: PropTypes.object,
};

CaseStudyTemplate.defaultProps = {
  image2: null,
  image3: null,
  layout: null,
  helmet: null,
};

class CaseStudy extends React.Component {
  constructor(props) {
    super(props);
    this.popDownNavBar = React.createRef();
    this.caseStudyRef = React.createRef();
    this.post = props.data.markdownRemark;
  }

  componentDidMount() {
    document.documentElement.classList.toggle('noscroll', false);
  }

  render() {
    return (
      <CaseStudyTemplate
        contentComponent={HTMLContent}
        topDescription={this.post.frontmatter.top_description}
        problemSolvedTitle={
          this.post.frontmatter.problem_solved_alternate || 'Problem Solved'
        }
        brief={this.post.frontmatter.brief}
        solutionTitle={
          this.post.frontmatter.solution_alternate || 'The Solution'
        }
        solution={this.post.frontmatter.solution}
        detailsTitle={this.post.frontmatter.details_alternate || 'The Details'}
        details={this.post.frontmatter.details}
        image1={this.post.frontmatter.image_1}
        image2={this.post.frontmatter.image_2}
        image3={this.post.frontmatter.image_3}
        helmet={
          <Helmet titleTemplate="%s | Lab3 Apps">
            <title>{`${this.post.frontmatter.title_short}`}</title>
            <meta
              name="description"
              content={this.post ? this.post.frontmatter.topDescription : ''}
            />
            <meta
              property="og:title"
              content={this.post.frontmatter.title_short}
            />{' '}
            <meta property="og:type" content="article" />
            <meta property="og:url" content="" />
            <meta
              property="og:image"
              content={`https://lab3.com${this.post.frontmatter.image.childImageSharp.gatsbyImageData.src}`}
            />{' '}
            <meta
              property="og:description"
              content={this.post.frontmatter.topDescription}
            />
          </Helmet>
        }
        title={this.post.frontmatter.title_short}
      />
    );
  }
}

CaseStudy.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default CaseStudy;

export const pageQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title_short
        top_description
        problem_solved_alternate
        brief
        solution_alternate
        solution
        details_alternate
        details
        tags
        image {
          childImageSharp {
            gatsbyImageData(
              height: 600
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
        image_1 {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          publicURL
        }
        image_2 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
        image_3 {
          childImageSharp {
            gatsbyImageData(
              width: 740
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
