import { Link } from 'gatsby';
import React from 'react';

import * as styles from './ContentFooter.module.scss';

const ContentFooter = (link) => {
  return (
    <div className={styles.contentFooter}>
      <Link to={link.target}>{link.text}</Link>
    </div>
  );
};

export default ContentFooter;
