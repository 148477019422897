// extracted by mini-css-extract-plugin
export var animatedDate = "CaseStudy-module--animated-date--s-zTv";
export var animatedThumbnail = "CaseStudy-module--animated-thumbnail--MqqrM";
export var articleContent = "CaseStudy-module--article-content--IEC+Z";
export var briefBold = "CaseStudy-module--brief-bold--nGQnd";
export var detailsContent = "CaseStudy-module--details-content--Wnph3";
export var detailsSection = "CaseStudy-module--details-section--miC8z";
export var fadeIn = "CaseStudy-module--fade-in--9oU76";
export var fadeInText = "CaseStudy-module--fade-in-text--0Q5KA";
export var fadeInThumbnail = "CaseStudy-module--fade-in-thumbnail--T0emW";
export var headerContent = "CaseStudy-module--header-content--i0siz";
export var headerSection = "CaseStudy-module--header-section--A9TTV";
export var mainContent = "CaseStudy-module--main-content--LXHpD";
export var projectBrief = "CaseStudy-module--project-brief--30FTr";
export var projectBriefContent = "CaseStudy-module--project-brief-content--Lwdqa";
export var projectContent = "CaseStudy-module--project-content--arp6x";
export var projectDetails = "CaseStudy-module--project-details--x7yan";
export var projectHeader = "CaseStudy-module--project-header--4Xz0u";
export var projectImage = "CaseStudy-module--project-image--O0Ztr";
export var solutionContent = "CaseStudy-module--solution-content--4zVh0";
export var solutionImage = "CaseStudy-module--solution-image--S4xv8";
export var solutionSection = "CaseStudy-module--solution-section--06KXm";